import PaginationData from "@/models/general/PaginationData";
import Employee from "./Employee";
import EmployeesFilter from "./EmployeesFilter";

export default class Employees {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.status = 0;
    this.msg = "";
    this.pagination = new PaginationData();
    this.employeesData = [];
    this.employee = new Employee();
    this.filterData = new EmployeesFilter();
  }
  fillData(data) {
    this.status = data.status;
    this.msg = data.msg;
    this.pagination.fillData(data.employeesPagination);
    this.employeesData = data.employeesPagination.employeesData;
  }
}
