<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg
      v-if="exceptionMsg"
      :msg="exceptionMsg"
      :image="exceptionImg"
    />

    <div
      v-if="
        employees.employeesData != undefined &&
        employees.employeesData.length != 0
      "
    >
      <div class="row">
        <EmployeeCard
          v-for="employee in employees.employeesData"
          :key="employee.employeeToken"
          :employeeData="employee"
          v-on:setEmployeeData="employees.employee.fillData($event)"
        />
      </div>
      <Pagination
        v-if="!isLoading"
        :paginationData="employees.pagination"
        v-on:changePage="changePage"
      />
      <EmployeeInfo :employeeData="employees.employee" />
      <EmployeeDelete
        :employeeData="employees.employee"
        v-on:refresh="getEmployees()"
      />
    </div>

    <EmployeeFloatBtns
      :theFilterData="employees.filterData"
      v-on:search="search($event)"
    />
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS } from "@/utils/constants";
import { mapGetters } from "vuex";
import EmployeeCard from "@/components/employees/employees/EmployeeCard.vue";
import EmployeeInfo from "@/components/employees/employees/EmployeeInfo.vue";
import EmployeeDelete from "@/components/employees/employees/EmployeeDelete.vue";
import EmployeeFloatBtns from "@/components/employees/employees/EmployeeFloatBtns.vue";
import Pagination from "@/components/general/Pagination.vue";
import Employees from "@/models/employees/employees/Employees";
import createToastMixin from "@/utils/create-toast-mixin";

export default {
  name: "Employees",
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    EmployeeCard,
    EmployeeInfo,
    EmployeeDelete,
    EmployeeFloatBtns,
    Pagination,
  },
  data() {
    return {
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken,
      language: localStorage.getItem("userLanguage") || "ar",
      employees: new Employees(),
    };
  },
  methods: {
    changePage(page) {
      this.employees.pagination.selfPage = page;
      this.getEmployees();
    },
    search(data) {
      this.employees.filterData.fillData(data);
      this.employees.employeesData = [];
      this.getEmployees();
    },
    async getEmployees() {
      this.isLoading = true;
      try {
        const response = await this.employees.employee.getEmployees(
          this.language,
          this.userAuthorizeToken,
          this.employees.pagination,
          this.employees.filterData
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.exceptionMsg = null;
          this.employees.fillData(response.data);
        } else if (response.data.status == STATUS.NO_CONTENT) {
          this.employees.employeesData = [];
          this.exceptionMsg = response.data.msg;
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.employees.employeesData = [];
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.employees.employeesData = [];
          this.exceptionMsg = response.data.msg;
          this.showMsg(response.data.msg);
        }
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
        this.employees.employeesData = [];
      }
      this.isLoading = false;
    },
    showMsg(msg = "", isSuccess = false) {
      let variant = isSuccess ? "success" : "danger";
      this.$bvToast.toast(`${msg}`, {
        title: this.$t("alert"),
        variant: variant,
        toaster: "b-toaster-bottom-center",
        autoHideDelay: 5000,
        // noAutoHide: true,
      });
    },
  },
  computed: {
    ...mapGetters(["userData"]),
  },
  async created() {
    this.getEmployees();
  },
};
</script>
